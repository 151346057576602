import React from 'react';
import Box from '@material-ui/core/Box';
import { WhatsAppIcon } from '@oxygen/ab/Icons';
import PricingCalculator from '../pricing-calculator';
import {
  Wrapper,
  SectionTitle,
  SectionSubtext,
  StatsSection,
  StatsWrapper,
  CallUsButton,
  WhatsAppButton,
  StatsTitle,
  StatsDesc,
  StatsBox,
  StatsBoxWithBorder,
  PrimaryButton
} from './styles';

const PricingSection = () => (
  <Wrapper>
    <SectionTitle>
      Save thousands when you sell through us and ONLY pay once it&apos;s sold
    </SectionTitle>
    <SectionSubtext>
      We charge a fixed fee based on the value of your home, so you can save
      your money for things that actually matter - like that sassy new L-shaped
      couch you’ve been eyeing Leopard printed and all.
      <strong> The Fixed fee is subject to the Mandate listing Price</strong>.
    </SectionSubtext>
    <PricingCalculator />
    <StatsSection>
      <StatsWrapper>
        <StatsBox>
          <StatsTitle>5 hours</StatsTitle>
          <StatsDesc>We sell a home every 5 hours</StatsDesc>
        </StatsBox>
        <StatsBoxWithBorder>
          <StatsTitle>31%</StatsTitle>
          <StatsDesc>
            Our properties sell 31% faster than the industry average
          </StatsDesc>
        </StatsBoxWithBorder>
        <StatsBoxWithBorder>
          <StatsTitle>Savings</StatsTitle>
          <StatsDesc>Average savings of R40,000 per transaction</StatsDesc>
        </StatsBoxWithBorder>
      </StatsWrapper>
      <div>
        <PrimaryButton component='a' href='/valuation/'>
          Book a free evaluation
        </PrimaryButton>
        <Box
          mt={2}
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
        >
          <CallUsButton component='a' href='tel:+27105903088'>
            Call us
          </CallUsButton>
          {/*
            NB: Manually configured whatsapp URL. Does not work when we fetch the whatsapp url from config (like we do in other places).
           */}
          <WhatsAppButton
            component='a'
            target='_blank'
            href='https://api.whatsapp.com/send?phone=27838436190'
            startIcon={<WhatsAppIcon />}
          >
            Whatsapp
          </WhatsAppButton>
        </Box>
      </div>
    </StatsSection>
  </Wrapper>
);

export default React.memo(PricingSection);
