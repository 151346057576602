import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { Room, Search } from 'styled-icons/material';
import { colors, breakpoints } from '@oxygen/theme';

const boxShadow = '0 2px 4px 0 rgba(65, 65, 96, 0.18)';
const animate = (prop, duration = 0.1) => `${prop} ${duration}s ease-in-out`;

export const StyledLinkButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #ff585d;
      border-radius: 5.2488px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
      height: 50px;
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;
  position: relative;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  z-index: 2;
  max-width: 450px;
`;

export const SearchInputContainer = styled.div`
  background: ${colors.white};
  border: 2px solid;
  border-right: ${(props) => (props.hideActionButton ? '2px solid ' : 'none')};
  padding: 10px 20px;
  border-color: ${(props) => (props.error ? colors.sunray2 : '#f2f3f6')};
  border-radius: ${(props) => (props.hideActionButton ? '4px' : '4px 0 0 4px')};
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
`;

export const Input = styled.input`
  width: 100%;
  height: 4.3rem;
  outline: none;
  border: none;
  font-size: 1.5rem;
  color: ${colors.storm3};
  font-family: 'Poppins', sans-serif;
  &::placeholder {
    font-size: 1.5rem;
    color: ${colors.ghost};
  }
`;

export const ResultsContainer = styled.div`
  box-shadow: ${boxShadow};
  width: 100%;
  max-height: 400px;
  overflow-y: visible;
  padding: 0;
  border-top: 0;
  background: ${colors.white};
  border-left: 1px solid ${colors.overcast3};
  border-right: 1px solid ${colors.overcast3};
  position: absolute;
  top: 50px;
  z-index: ${({ visible }) => (visible ? 1000 : -1)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translateY(${({ visible }) => (visible ? '0' : '-10%')});
  transition: ${animate('transform', 0.2)}, ${animate('opacity')},
    ${animate('z-index', 0.2)};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const ResultItem = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${colors.white};
  user-select: none;
  outline: none;
  cursor: pointer;
  border-top: 1px solid ${colors.overcast3};
  box-shadow: ${boxShadow};
  &:hover {
    background-color: ${({ disable }) => !disable && colors.overcast3};
  }
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  cursor: ${({ disable }) => disable && 'default'};
  opacity: ${({ disable }) => disable && 0.4};
`;

export const TownName = styled.div`
  color: ${colors.storm4};
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Poppins', sans-serif;
`;

export const SuburbName = styled.div`
  color: ${colors.storm1};
  font-size: 1.5rem;
  width: 100%;
  max-width: 300px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  font-family: 'Poppins', sans-serif;
  @media (max-width: ${breakpoints.small}) {
    max-width: 225px;
  }
`;

export const MapIcon = styled(Room)`
  height: 20px;
  color: ${colors.storm4};
  margin-left: 8px;
  margin-right: 8px;
`;

export const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8333;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  background-color: ${colors.gallery};
  color: ${colors.storm4};
`;

export const LoadingBar = styled.div`
  overflow: hidden;
  border-radius: 5px;
  background: repeating-linear-gradient(
    45deg,
    #4db3bf,
    #4db3bf 10.5px,
    #6bbfca 10.5px,
    #6bbfca 12.5px
  );
  margin: 0 8px;
  animation: moveBackground 5s linear infinite;
  flex-grow: 1;
  height: 10px;
`;

export const SearchIcon = styled(Search)`
  height: 20px;
  color: ${colors.storm4};
  margin-left: 8px;
  margin-right: 8px;
`;

export const Label = styled.label`
  color: ${colors.white};
  font-size: 1.6em;
`;
