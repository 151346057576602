import sandtonImg from './images/sandton.png?resize'; // eslint-disable-line import/no-unresolved
import pretoriaImg from './images/pretoria.png?resize'; // eslint-disable-line import/no-unresolved
import hatfieldImg from './images/hatfield.png?resize'; // eslint-disable-line import/no-unresolved

export const sandtonProperty = {
  searchUrl: '/search/property-for-sale/gauteng/sandton/5',
  photoUrl: sandtonImg.src,
  suburb: 'Sandton',
  price: 2900000,
  beds: 3,
  baths: 3,
  parking: 1
};

export const pretotriaProperty = {
  searchUrl: '/search/property-for-sale/gauteng/pretoria/2',
  photoUrl: pretoriaImg.src,
  suburb: 'Pretoria',
  price: 1799000,
  beds: 2,
  baths: 3,
  parking: 1
};

export const hatfieldProperty = {
  searchUrl: '/search/property-for-sale/gauteng/pretoria/2',
  photoUrl: hatfieldImg.src,
  suburb: 'Hatfield',
  price: 849000,
  beds: 2,
  baths: 1,
  parking: 1
};
