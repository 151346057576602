import React from 'react';
import Seo from 'components/seo';
import HeroSection from 'components/o2/hero-section';
import RebrandLayout from '@oxygen/ab/RebrandLayout';
import PricingSection from 'components/o2/pricing-section';
import LookingToBuySection from 'components/o2/looking-to-buy-section';
import SellingExperienceSection from 'components/o2/selling-experience-section';
import ReviewsSection from 'components/o2/reviews-section';
import FeaturesSection from 'components/o2/features-section';
import TechInnovationsSection from '../components/o2/tech-innovations';
import FooterSection from 'components/o2/footer-section';

const HomePage = () => {
  return (
    <RebrandLayout fluid>
      <Seo
        title='Find and Sell Property | Real Estate Simplified'
        description="Leadhome is a 24/7 full-service real estate agency. Experience expert agent guidance and access to real-time dashboards to monitor your property's performance."
      />
      <HeroSection />
      <PricingSection />
      <SellingExperienceSection />
      <ReviewsSection />
      <FeaturesSection />
      <TechInnovationsSection />
      <LookingToBuySection />
      <FooterSection />
    </RebrandLayout>
  );
};

export default HomePage;
