import React from 'react';
import PropTypes from 'prop-types';
import {
  IndividualFeatureCardWrapper,
  FeatureCardTitle,
  FeatureCardBlurb,
  FeatureCardImageWrapper
} from './styles';

const FeatureCard = ({ image, title, blurb }) => (
  <IndividualFeatureCardWrapper item md={4} sm={6} xs={12}>
    <FeatureCardImageWrapper>{image}</FeatureCardImageWrapper>
    <FeatureCardTitle>{title}</FeatureCardTitle>
    <FeatureCardBlurb>{blurb}</FeatureCardBlurb>
  </IndividualFeatureCardWrapper>
);

FeatureCard.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  blurb: PropTypes.string
};

export default React.memo(FeatureCard);
