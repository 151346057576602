import React from 'react';
import { Wrapper } from './style';
import FacebookSvg from '../../images/facebook-logo.svg';
import GoogleSvg from '../../images/google-logo.svg';
import VerticalGreyLineSvg from '../../images/vertical-grey-line.svg';

const FacebookAndGoogleLogo = () => (
  <Wrapper>
    <FacebookSvg />
    <VerticalGreyLineSvg />
    <GoogleSvg />
  </Wrapper>
);

export default React.memo(FacebookAndGoogleLogo);
