import React from 'react';
import { SvgIcon } from '@material-ui/core';

function BedsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M0.405273 0.530273H13.875V5.42557H12.9771V1.34616H1.30326V5.42557H0.405273V0.530273Z'
        fill='currentColor'
      />
      <path
        d='M5.79318 5.42557H2.20124V4.60969H5.79318V5.42557Z'
        fill='currentColor'
      />
      <path
        d='M0.405273 12.7685H1.30326V9.50499H12.9771V12.7685H13.875V6.24145H0.405273V12.7685Z'
        fill='currentColor'
      />
      <path
        d='M8.48713 5.42557H12.0791V4.60969H8.48713V5.42557Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

BedsIcon.defaultProps = {
  viewBox: '0 0 14 13'
};

export default React.memo(BedsIcon);
