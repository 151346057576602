import {
  ReviewCardWrapper,
  PersonNameCardHeader,
  ReviewBlurb,
  ImageSectionWrapper,
  TextSectionWrapper
} from './styles';
import React from 'react';
import { PersonFaceImage } from '../../styles';
import FiveStarsSvg from '../../images/five-stars.svg';
import PropTypes from 'prop-types';

const ReviewCard = React.forwardRef(({ image, personName, blurb }) => {
  return (
    <ReviewCardWrapper>
      <ImageSectionWrapper>
        <PersonFaceImage src={image} alt='person picture 4' />
      </ImageSectionWrapper>
      <TextSectionWrapper>
        <PersonNameCardHeader>{personName}</PersonNameCardHeader>
        <ReviewBlurb>{blurb}</ReviewBlurb>
        <FiveStarsSvg />
      </TextSectionWrapper>
    </ReviewCardWrapper>
  );
});

ReviewCard.propTypes = {
  image: PropTypes.any,
  blurb: PropTypes.string,
  personName: PropTypes.string
};

export default React.memo(ReviewCard);
