/* eslint-disable indent */
import styled, { css } from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { MD, SM, XS } from '@oxygen/ab/Breakpoints';
import ImageComponent from '../../image';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  min-height: 500px;
  padding: 75px 10px;
  background: #ff585d;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;

  ${SM} {
    padding: 50px 10px 40px;
  }
`;

export const SectionTitle = styled.h2`
  z-index: 2;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 118%;
  text-align: center;
  color: #ffffff;
  max-width: 700px;
  margin-bottom: 38px;

  ${SM} {
    margin-bottom: 34px;
  }

  ${XS} {
    font-size: 25px;
    line-height: 118%;
    margin-bottom: 27px;
  }
`;

export const SectionSubtext = styled.p`
  z-index: 2;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  font-size: 20px;
  line-height: 118%;
  color: #ffffff;
  max-width: 700px;
  margin-bottom: 50px;

  ${XS} {
    font-size: 15px;
  }
`;

export const DarkButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #1b1d36;
      border-radius: 5.2488px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17.0677px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.984675px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
    }
  }
`;

export const StepInfo = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  padding: 25px 36px 49px;
  background-color: #ffffff;
  border-radius: 2px 35px 2px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
`;

export const CardContent = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

export const Card = styled.div`
  max-width: 275px;
  position: relative;
  padding: 25px 36px 49px;
  background-color: #ffffff;
  border-radius: 2px 35px 2px 35px;
  z-index: 2;

  &:hover {
    ${CardContent} {
      opacity: 0;
    }

    ${StepInfo} {
      top: 0;
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const StepNumberWrapper = styled.div`
  position: relative;
`;

export const StepNumber = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 93.8238px;
  line-height: 118%;
  color: #141a2f;
`;

export const StepNumberWord = styled.p`
  position: absolute;
  left: 42px;
  top: 25px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12.8067px;
  line-height: 19px;
  letter-spacing: 3.97448px;
  text-transform: uppercase;
  color: #141a2f;

  ${(props) =>
    props.stepNo === 1 &&
    css`
      left: 19px;
    `}

  span {
    color: #ffffff;
  }
`;

export const Divider = styled.div`
  height: 5px;
  width: 41px;
  background-color: #ff585d;
`;

export const StepTitle = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 113%;
  letter-spacing: -0.5px;
  color: #141a2f;
  margin-top: 13px;
`;

export const StepDescription = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #1b1d36;
  margin-bottom: 25px;
`;

export const Steps = styled.div`
  margin-bottom: 65px;
  max-width: 1280px;
  position: relative;

  ${SM} {
    max-width: 600px;
  }
`;

/* *********************
 * Shapes and things.
 ********************** */
export const MorseCodeImage = styled(ImageComponent)`
  position: absolute;
  z-index: 1;

  left: -60px;
  top: -130px;

  ${MD} {
    top: -30px;
  }

  ${SM} {
  }

  ${XS} {
  }
`;

export const WhiteLineSvgWrapper = styled.span`
  z-index: 2;
  position: absolute;

  right: 920px; /* Aligned right to move out of the way of title text */
  top: -170px;
`;

export const BlueBlockSvgWrapper = styled.span`
  position: absolute;

  right: 200px;
  top: -170px;

  @media (max-width: 1110px) {
    top: -190px;
  }
  ${SM} {
    top: -190px;
  }
  @media (max-width: 650px) {
    top: -210px;
  }
  ${XS} {
    display: none;
  }
`;

export const SmallYellowEllipseSvgWrapper = styled.span`
  position: absolute;

  right: 50px;
  top: -130px;

  @media (max-width: 1110px) {
    right: 0px;
    top: -90px;
  }

  ${XS} {
    display: none;
  }
`;
