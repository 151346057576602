import React from 'react';
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';

const Seo = ({ title, description, openGraph, ...rest }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{ title, description, ...openGraph }}
      {...rest}
    />
  );
};

Seo.propTypes = {
  openGraph: PropTypes.object,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Seo;
