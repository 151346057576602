import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  StepNumber,
  StepNumberWord,
  Divider,
  StepTitle,
  CardContent,
  StepInfo,
  StepDescription,
  StepNumberWrapper
} from './styles';

const SellingExperienceCard = ({ step }) => (
  <Card>
    <CardContent>
      <div>
        <StepNumberWrapper>
          <StepNumber>{step.stepNo}</StepNumber>
          <StepNumberWord stepNo={step.stepNo}>
            {step.stepNoWord}
          </StepNumberWord>
        </StepNumberWrapper>
      </div>
      <Divider />
      <StepTitle>{step.title}</StepTitle>
    </CardContent>
    <StepInfo>
      <StepDescription>{step.description}</StepDescription>
      <Divider />
    </StepInfo>
  </Card>
);

SellingExperienceCard.propTypes = {
  step: PropTypes.any
};

export default React.memo(SellingExperienceCard);
