import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import MdUpHeroSection from './md-up';
import SmDownHeroSection from './sm-down';

const HeroSection = () => (
  <>
    <Hidden smDown>
      <MdUpHeroSection />
    </Hidden>
    <Hidden mdUp>
      <SmDownHeroSection />
    </Hidden>
  </>
);

export default React.memo(HeroSection);
