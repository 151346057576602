import React, { useState, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { toCurrency, toPercentage } from 'utils/format';
import calculate from './calculate';
import {
  PricingCalculatorWrapper,
  CalculatorWrapper,
  SlidesSection,
  OutputSection,
  PriceComparisonSection,
  SavingsSection,
  SliderLabel,
  SliderValue,
  Slider,
  CompareLabel,
  CompareValue,
  CompareDivider,
  SavingsLabel,
  SavingsValue,
  SavingsNote,
  OurExcellentServiceWillSaveYouFromTheDrama,
  LeftCalculateYourSavingsTextWrapper,
  LeftCalculateYourSavingsArrowWrapper,
  TopCalculateYourSavingsTextWrapper,
  TopCalculateYourSavingsArrowWrapper
} from './styles';
import BlueArrowSvg from './images/blue-arrow.svg';
import { Hidden } from '@material-ui/core';

const getSavingsCopy = ({ selling, viewingPackage, percentage }) => {
  if (viewingPackage) {
    return null;
  }

  return null;
};

const PricingCalculator = () => {
  const [listingPrice, setListingPrice] = useState(700000);
  const [competitorCommissionPerc, setCompetitorCommissionPerc] = useState(6.5);
  // eslint-disable-next-line no-unused-vars
  const [filter, _] = useState({
    selling: true,
    viewingPackage: false
  });
  // eslint-disable-next-line no-unused-vars
  const handleChangeSlider = (_, newValue) => {
    setCompetitorCommissionPerc(newValue);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangePrice = (_, newValue) => {
    setListingPrice(newValue);
  };

  const { selling, viewingPackage } = filter;

  const { savings, competitorsFee, fixedFee, percentage } = useMemo(
    () =>
      calculate({
        listingPrice,
        competitorCommissionPerc,
        viewingPackage
      }),
    [listingPrice, competitorCommissionPerc, viewingPackage]
  );

  const ourPriceSection =
    percentage > 0 ? (
      <>
        <CompareLabel>Our fixed fee</CompareLabel>
        <CompareValue>{toPercentage(percentage)}</CompareValue>
        <span>excl. VAT</span>
      </>
    ) : (
      <>
        <CompareLabel>Our fixed fee</CompareLabel>
        <CompareValue>R{toCurrency(fixedFee)}</CompareValue>
        <span>excl. VAT</span>
      </>
    );

  return (
    <PricingCalculatorWrapper>
      <Hidden mdUp>
        {/* When size is small and lower, we show a smaller
                'calculate your savings' section _above_ the checkboxes. */}
        <TopCalculateYourSavingsTextWrapper>
          Calculate your
          <br /> savings
        </TopCalculateYourSavingsTextWrapper>
        <TopCalculateYourSavingsArrowWrapper>
          <BlueArrowSvg />
        </TopCalculateYourSavingsArrowWrapper>
      </Hidden>
      <CalculatorWrapper>
        <SlidesSection>
          <Hidden smDown>
            {/* When size is medium and up, we show a larger
                'calculate your savings' section to the left. */}
            <LeftCalculateYourSavingsTextWrapper>
              Calculate your
              <br /> savings
            </LeftCalculateYourSavingsTextWrapper>
            <LeftCalculateYourSavingsArrowWrapper>
              <BlueArrowSvg />
            </LeftCalculateYourSavingsArrowWrapper>
          </Hidden>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <SliderLabel>Price of home</SliderLabel>
            <SliderValue>R{toCurrency(listingPrice)}</SliderValue>
          </Box>
          <Slider
            name='listingPrice'
            aria-label='pricing slider'
            value={listingPrice}
            onChange={handleChangePrice}
            step={50000}
            min={100000}
            max={10000000}
          />
          <Box
            mt={3}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <SliderLabel>Traditional agents fee</SliderLabel>
            <SliderValue>{competitorCommissionPerc}%</SliderValue>
          </Box>
          <Slider
            name='competitorCommissionPerc'
            aria-label='commission percentage slider'
            value={competitorCommissionPerc}
            onChange={handleChangeSlider}
            step={0.25}
            min={2.5}
            max={10}
          />
        </SlidesSection>
        <OutputSection>
          <PriceComparisonSection>
            <CompareLabel>Our competitors price</CompareLabel>
            <CompareValue>R{toCurrency(competitorsFee)}</CompareValue>
            <span>excl. VAT</span>
            <CompareDivider />
            {ourPriceSection}
          </PriceComparisonSection>
          <SavingsSection>
            {savings > 0 ? (
              <>
                <SavingsLabel>You could SAVE</SavingsLabel>
                <SavingsValue>R{toCurrency(savings)}</SavingsValue>
                <span>excl. VAT</span>
                <SavingsNote>
                  {getSavingsCopy({ selling, viewingPackage, percentage })}
                </SavingsNote>
              </>
            ) : (
              <OurExcellentServiceWillSaveYouFromTheDrama>
                Our excellent service
                <br />
                will save you from
                <br />
                all the drama!
              </OurExcellentServiceWillSaveYouFromTheDrama>
            )}
          </SavingsSection>
        </OutputSection>
      </CalculatorWrapper>
    </PricingCalculatorWrapper>
  );
};

export default React.memo(PricingCalculator);
