import React from 'react';
import {
  Wrapper,
  OurMostPopularFeatures,
  WhyPeopleSayWeAreSoAhead,
  FeatureCardsContainer,
  FeatureCardsContainerContainer
} from './styles';
import FeatureCard from './feature-card';

import HouseSvg from './images/house.svg';
import CameraSvg from './images/camera.svg';
import KeyStarsSvg from './images/key-stars.svg';
import PhoneHouseSvg from './images/phone-house.svg';
import SoldPersonSvg from './images/sold-person.svg';
import StarsSvg from './images/stars.svg';

const FeaturesSection = () => (
  <Wrapper>
    <OurMostPopularFeatures>Our most popular features</OurMostPopularFeatures>
    <WhyPeopleSayWeAreSoAhead>
      Why people say we are so ahead
    </WhyPeopleSayWeAreSoAhead>

    <FeatureCardsContainerContainer>
      <FeatureCardsContainer container spacing={2} justifyContent='center'>
        <FeatureCard
          image={<HouseSvg />}
          title='Expert home evaluations'
          blurb='Using data & area knowledge'
        />
        <FeatureCard
          image={<CameraSvg />}
          title='Professional photography'
          blurb='Great photos attract buyers'
        />
        <FeatureCard
          image={<SoldPersonSvg />}
          title='Expert local agents'
          blurb='Over 6,000 properties sold'
        />
        <FeatureCard
          image={<KeyStarsSvg />}
          title='Over 52,000 active buyers'
          blurb='180,000+ viewings booked'
        />
        <FeatureCard
          image={<PhoneHouseSvg />}
          title="Monitor your property's performance 24/7"
          blurb='Insights on your phone'
        />
        <FeatureCard
          image={<StarsSvg />}
          title='Dedicated support team'
          blurb='Thousands of 5-star reviews'
        />
      </FeatureCardsContainer>
    </FeatureCardsContainerContainer>
  </Wrapper>
);

export default React.memo(FeaturesSection);
