export const pricingBrackets = [
  {
    min: 0,
    max: 700000,
    fee: 24995
  },
  {
    min: 700001,
    max: 1000000,
    fee: 39995
  },
  {
    min: 1000001,
    max: 1250000,
    fee: 49995
  },
  {
    min: 1250001,
    max: 1500000,
    fee: 54995
  },
  {
    min: 1500001,
    max: 1750000,
    fee: 64995
  },
  {
    min: 1750001,
    max: 2000000,
    fee: 69995
  },
  {
    min: 2000001,
    max: 2500000,
    fee: 74995
  },
  {
    min: 2500001,
    max: 3000000,
    fee: 79995
  },
  {
    min: 3000001,
    max: 3500000,
    fee: 89995
  },
  {
    min: 3500001,
    max: 4000000,
    fee: 99995
  }
];

export const VIEWING_PACKAGE = 15000;
export const CASHBACK = 15000;

// for over 4,000,001
export const LISTING_FEE_PERC = 2.5;
export const VIEWING_PACKAGE_PERC = 1;
export const CASHBACK_PERC = 0.5;
