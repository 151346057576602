import React from 'react';
import { PricingBox, Price, SellOnly } from './styles';

const AnimatedPricing = () => {
  const sellingOnlyClass = 'slide-in';

  return (
    <PricingBox>
      <SellOnly className={sellingOnlyClass}>
        <Price>R24,995 + VAT</Price>
      </SellOnly>
    </PricingBox>
  );
};

export default React.memo(AnimatedPricing);
