import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f9;
  padding: 0 0 50px 0;
`;

export const OurMostPopularFeatures = styled.div`
  font-family: 'Caveat', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 86.5%;
  text-align: center;
  color: #88dbdf;

  /* Default width >=1440px */
  font-size: 38px;
  margin-top: 70px;
`;

export const WhyPeopleSayWeAreSoAhead = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 118%;
  text-align: center;

  /* Default width >=1440px */
  font-size: 35px;
  margin: 30px 20px 80px 20px;
`;

// This extra outer container is necessary to center
//  the grid (that is `FeatureCardsContainer`).
export const FeatureCardsContainerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FeatureCardsContainer = styled(Grid)`
  display: flex;
  max-width: 900px;
`;

export const IndividualFeatureCardWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-items: center;
  text-align: center;

  margin-top: 80px;
`;

export const FeatureCardImageWrapper = styled.div`
  height: 116px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  svg {
  }
`;

export const FeatureCardTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 123.5%;
  margin-bottom: 16px;
`;

export const FeatureCardBlurb = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.5%;
  margin-bottom: 40px;
`;
