import getConfig from 'next/config';
import { getBearer } from '@oxygen/ab/AuthenticationProvider';

const { serverRuntimeConfig } = getConfig();

function getQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');
}

export const fetcher = (path, data = null) => {
  const requestUrl = data ? path + '?' + getQueryString(data) : path;
  const apiUrl = serverRuntimeConfig.apiUrl || '';
  return fetch(`${apiUrl}${requestUrl}`).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

export const authFetcher = (url, bearer, data = null) => {
  const requestUrl = data ? url + '?' + getQueryString(data) : url;
  return fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer
    }
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

export const rawPost = (url, data) => {
  const apiUrl = serverRuntimeConfig.apiUrl || '';
  return fetch(`${apiUrl}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const post = (url, data) => {
  return rawPost(url, data).then((r) => {
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};

export const authPut = (url, data) => {
  const apiUrl = serverRuntimeConfig.apiUrl || '';
  return fetch(`${apiUrl}${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getBearer()
    },
    body: JSON.stringify(data)
  });
};

export const graphql = (data) => {
  const wpGraphQL = serverRuntimeConfig.wpGraphQL || '';
  return fetch(`${wpGraphQL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
};
