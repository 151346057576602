import React from 'react';
import PropertyCard from './property-card';
import Hidden from '@material-ui/core/Hidden';
import SuburbSearchInput from '../suburb-search-input';
import { Wrapper, SectionTitle, SectionSubtext, Properties } from './styles';
import {
  sandtonProperty,
  pretotriaProperty,
  hatfieldProperty
} from './constants';

const LookingToBuySection = () => (
  <Wrapper>
    <Hidden smDown>
      <SectionTitle>
        Looking to <span>buy a home</span>
      </SectionTitle>
    </Hidden>
    <Hidden mdUp>
      <SectionTitle>
        Looking to <br />
        <span>buy a home</span>
      </SectionTitle>
    </Hidden>
    <SuburbSearchInput
      toPropertySearch
      buttonText='Next'
      placeholder='Search suburb'
    />
    <SectionSubtext>Search over 4000 properties</SectionSubtext>
    <Hidden smDown>
      <Properties>
        <PropertyCard mt property={sandtonProperty} />
        <PropertyCard property={pretotriaProperty} />
        <PropertyCard mt property={hatfieldProperty} />
      </Properties>
    </Hidden>
    <Hidden mdUp>
      <Properties>
        <PropertyCard property={sandtonProperty} />
        <Hidden xsDown>
          <PropertyCard mt property={pretotriaProperty} />
        </Hidden>
      </Properties>
    </Hidden>
  </Wrapper>
);

export default React.memo(LookingToBuySection);
