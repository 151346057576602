import React, { useEffect, useRef } from 'react';
import {
  Wrapper,
  ThousandsOf5StarReviewsHeader,
  DontTakeOurWordForItHeader,
  PersonFaceImage,
  ReviewsHeader,
  ReviewsWrapper,
  ReviewsOverflowWrapper,
  BigEllipse1Wrapper,
  SmallOrangeEllipse1Wrapper,
  SmallRedEllipse2Wrapper,
  BlueLine1Wrapper,
  RedLine2Wrapper,
  FloatingSmallPerson1Wrapper,
  FloatingMedPerson2Wrapper,
  FloatingMedPerson3Wrapper,
  FloatingSmallPerson4Wrapper
} from './styles';

import ReviewCard from './components/review-card';
import FacebookAndGoogleLogo from './components/facebook-and-google';

import BigEllipse1Svg from './images/big-ellipse1.svg';
import Line1Svg from './images/line1.svg';
import Line2Svg from './images/line2.svg';
import SmallEllipse1Svg from './images/small-ellipse1.svg';
import SmallEllipse2Svg from './images/small-ellipse2.svg';

import Person1Png from './images/person1.png';
import Person2Png from './images/person2.png';
import Person3Png from './images/person3.png';
import Person4Png from './images/person4.png';
import Person5Png from './images/person5.png';
import ProfileIconPng from './images/profile-icon.png';
import PersonAndrePng from './images/person-andre.png';
import PersonAshleighPng from './images/person-ashleigh.png';
import PersonLeighPng from './images/person-leigh.png';

const ReviewsSection = () => {
  const scrollAreaRef = useRef();

  useEffect(() => {
    if (scrollAreaRef && scrollAreaRef.current) {
      scrollAreaRef.current.scrollLeft = 150;
    }
  }, [scrollAreaRef]);

  return (
    <Wrapper>
      <ThousandsOf5StarReviewsHeader>
        Thousands of 5 star reviews
      </ThousandsOf5StarReviewsHeader>
      <DontTakeOurWordForItHeader>
        Don&apos;t take our word for it
        <br />
        take our clients&apos;
      </DontTakeOurWordForItHeader>

      <FacebookAndGoogleLogo />
      <ReviewsHeader>Reviews</ReviewsHeader>

      <ReviewsOverflowWrapper>
        <ReviewsWrapper ref={scrollAreaRef}>
          <ReviewCard
            image={ProfileIconPng}
            personName='Anthon Bakker'
            blurb='Very impressive system that keeps me updated all the time. All activities were recorded and sent to me instantaneously.'
          />
          <ReviewCard
            image={PersonAshleighPng}
            personName='Ashleigh Burningham'
            blurb='Next level experience with Leadhome. Received an offer on my property within the first week. Excellent service!'
          />
          <ReviewCard
            image={PersonLeighPng}
            personName='Leigh Carrington'
            blurb='A new way of selling houses. Prompt and efficient service, holding your hand through the process. Very happy.'
          />
          <ReviewCard
            image={PersonAndrePng}
            personName='Andre Ferreira'
            blurb='Confident, focused and driven to bring the entire process together, for a smooth transition regardless if you buy or sell.'
          />
          <ReviewCard
            image={Person5Png}
            personName='Lucinda van Heerden'
            blurb='I have already and will definitely refer Leadhome to anyone selling a property. They are in my opinion market leaders.'
          />
        </ReviewsWrapper>
      </ReviewsOverflowWrapper>
      {/* Floating faces */}
      <FloatingSmallPerson1Wrapper>
        <PersonFaceImage src={Person1Png} alt='person picture 1' />
      </FloatingSmallPerson1Wrapper>
      <FloatingMedPerson2Wrapper>
        <PersonFaceImage src={Person2Png} alt='person picture 2' />
      </FloatingMedPerson2Wrapper>
      <FloatingMedPerson3Wrapper>
        <PersonFaceImage src={Person3Png} alt='person picture 3' />
      </FloatingMedPerson3Wrapper>
      <FloatingSmallPerson4Wrapper>
        <PersonFaceImage src={Person4Png} alt='person picture 4' />
      </FloatingSmallPerson4Wrapper>

      {/* Background shapes */}
      <BigEllipse1Wrapper>
        <BigEllipse1Svg />
      </BigEllipse1Wrapper>
      <BlueLine1Wrapper>
        <Line1Svg />
      </BlueLine1Wrapper>
      <RedLine2Wrapper>
        <Line2Svg />
      </RedLine2Wrapper>
      <SmallOrangeEllipse1Wrapper>
        <SmallEllipse1Svg />
      </SmallOrangeEllipse1Wrapper>
      <SmallRedEllipse2Wrapper>
        <SmallEllipse2Svg />
      </SmallRedEllipse2Wrapper>
    </Wrapper>
  );
};

export default React.memo(ReviewsSection);
