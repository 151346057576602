import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-graceful-image';

const StyledPicture = styled.picture`
  display: block;
  width: 100%;
  max-width: 100%;
  img,
  source {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`;

const LazyImage = ({
  classname,
  src,
  sizes,
  srcset,
  placeholder,
  webp,
  alt,
  ...rest
}) => (
  <ProgressiveImage
    src={src}
    srcSetData={{
      srcSet: srcset,
      sizes:
        sizes || '(min-width: 768px) 960px, (min-width: 640px) 640px, 460px'
    }}
    placeholder={placeholder}
  >
    {(src, loading, srcSetData) => (
      <StyledPicture classname={classname}>
        {loading ? (
          <img alt={alt} src={placeholder} />
        ) : (
          <>
            {webp && (
              <source
                srcSet={webp}
                sizes={srcSetData.sizes}
                type='image/webp'
              />
            )}
            {webp && (
              <source
                srcSet={srcSetData.srcSet}
                sizes={srcSetData.sizes}
                type='image/jpeg'
              />
            )}
            <img
              alt={alt}
              src={src}
              srcSet={srcSetData.srcSet}
              sizes={srcSetData.sizes}
              {...rest}
            />
          </>
        )}
      </StyledPicture>
    )}
  </ProgressiveImage>
);

LazyImage.propTypes = {
  alt: PropTypes.string,
  classname: PropTypes.string,
  placeholder: PropTypes.string,
  sizes: PropTypes.string,
  srcset: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  webp: PropTypes.string
};

export default LazyImage;
