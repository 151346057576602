import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ParkingIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M6.81376 0.606259C6.96987 0.504945 7.17958 0.504945 7.3357 0.606259L13.6216 4.68567C13.7396 4.76225 13.8096 4.88588 13.8096 5.01763V12.3606C13.8096 12.5859 13.6086 12.7685 13.3606 12.7685H12.0136V6.24145H2.13581V12.7685H0.788836C0.540864 12.7685 0.339844 12.5859 0.339844 12.3606V5.01763C0.339844 4.88588 0.409871 4.76225 0.527865 4.68567L6.81376 0.606259Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.0338 12.7685H11.1157V9.50499H3.0338V12.7685ZM8.4217 11.1368H5.72775V10.3209H8.4217V11.1368Z'
        fill='currentColor'
      />
      <path
        d='M11.1157 8.6891V7.05734H3.0338V8.6891H11.1157Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

ParkingIcon.defaultProps = {
  viewBox: '0 0 14 13'
};

export default React.memo(ParkingIcon);
