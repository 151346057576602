import styled from 'styled-components';
import MuiSlider from '@material-ui/core/Slider';
import { LG, MD, SM, XS } from '@oxygen/ab/Breakpoints';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

export const FormControlLabel = styled(MuiFormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 123.5%;
      color: #1b1d36;
    }
  }
`;

export const DarkCheckbox = styled(MuiCheckbox)`
  && {
    .MuiIconButton-label {
      color: #1b1d36;
    }
  }
`;

export const BrightCheckbox = styled(MuiCheckbox)`
  && {
    .MuiIconButton-label {
      color: #ff585d;
    }
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const CalculatorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;

  ${SM} {
    flex-direction: column;
  }
`;

export const PricingCalculatorWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${LG} {
  }

  ${MD} {
  }

  ${SM} {
    flex-direction: column;
  }

  ${XS} {
  }
`;

export const SlidesSection = styled.div`
  position: relative;
  width: 500px;
  height: 250px;
  color: #141a2f;
  padding: 47px 40px;
  background: #f2f3f6;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  ${MD} {
    width: 430px;
    padding: 43px 36px;
  }

  ${SM} {
    width: 100%;
    padding: 47px 12px;
  }
`;

export const OutputSection = styled.div`
  display: flex;

  ${SM} {
    width: 100%;
  }

  ${XS} {
    flex-direction: column;
  }
`;

export const PriceComparisonSection = styled.div`
  width: 280px;
  height: 250px;
  padding: 27px 40px;
  background: #88dbdf;
  color: #ffffff;

  ${MD} {
    width: 225px;
    padding: 19px 36px;
  }

  ${SM} {
    width: 100%;
    padding: 27px 12px;
  }
`;

export const SavingsSection = styled.div`
  width: 375px;
  color: #ffffff;
  height: 250px;
  padding: 47px 40px;
  background: #1b1d36;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 3px;
  position: relative;

  ${MD} {
    width: 315px;
    padding: 43px 36px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 16px;
  }

  ${SM} {
    width: 100%;
    padding: 47px 12px;
  }
`;

export const SliderLabel = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #141a2f;

  ${SM} {
    font-size: 17px;
  }
`;

export const SliderValue = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 134%;
  color: #141a2f;

  ${SM} {
    font-size: 20px;
  }
`;

export const Slider = styled(MuiSlider)`
  && {
    .MuiSlider-root {
      color: #88dbdf;
      height: 3px;
    }

    .MuiSlider-thumb {
      height: 25px;
      width: 25px;
      background: #ffffff;
      border: 8px solid #88dbdf;
      margin-top: -12.5px;
      margin-left: -12.5px;

      &:hover,
      &:focus,
      &:active {
        box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.3);
      }
    }

    .MuiSlider-track {
      height: 3px;
      color: #88dbdf;
      border-radius: 3px;
    }

    .MuiSlider-rail {
      height: 3px;
      color: #b1b2c0;
      border-radius: 3px;
    }
  }
`;

export const CompareLabel = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 123.5%;
  color: #ffffff;
`;

export const CompareValue = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.501845px;
  color: #ffffff;
  margin-top: 4px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10.6858px;
    line-height: 120%;
    letter-spacing: 0.164397px;
    color: #ffffff;
  }
`;

export const CompareDivider = styled.p`
  height: 1.5px;
  background-color: #ffffff;
  margin: 20px 0px;
`;

export const SavingsLabel = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 118%;
  color: #ffffff;
`;

export const SavingsValue = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 53.0337px;
  line-height: 118%;
  color: #ffffff;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10.6858px;
    line-height: 120%;
    letter-spacing: 0.164397px;
    color: #ffffff;
  }
`;

export const OurExcellentServiceWillSaveYouFromTheDrama = styled.p`
  font-family: 'Caveat', cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 86.5%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-top: 16px;
`;

export const SavingsNote = styled.p`
  font-family: 'Caveat', cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 86.5%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-top: 16px;
`;

/*
 * Calculate your savings, larger 'Left' variant shown at "medium and up" resolutions.
 */
export const LeftCalculateYourSavingsTextWrapper = styled.span`
  position: absolute;

  font-family: 'Caveat', cursive;
  font-style: normal;
  font-weight: normal;
  line-height: 86.5%;
  color: #88dbdf;

  font-size: 38px;
  top: -140px;
  left: 0;

  ${MD} {
    font-size: 25px;
    top: -90px;
    left: 0;
  }
`;
export const LeftCalculateYourSavingsArrowWrapper = styled.span`
  position: absolute;
  top: -90px;
  left: 95px;

  ${MD} {
    top: -70px;
    left: 60px;
    svg {
      width: 50px;
    }
  }
`;

/*
 * Calculate your savings, smaller 'Top' variant shown at "small and down" resolutions.
 */
export const TopCalculateYourSavingsTextWrapper = styled.span`
  position: relative;

  font-family: 'Caveat', cursive;
  font-style: normal;
  font-weight: normal;
  line-height: 86.5%;
  color: #88dbdf;

  font-size: 38px;
  left: -175px;
  top: 17px;

  ${SM} {
    font-size: 25px;
  }

  ${XS} {
    /* move closer to center above checkboxes */
    left: -40px;
    top: 30px;
  }
`;
export const TopCalculateYourSavingsArrowWrapper = styled.span`
  position: relative;
  left: -135px;
  top: 0;

  ${SM} {
    svg {
      width: 60px;
    }
  }

  ${XS} {
    /* move closer to center above checkboxes */
    left: 0;
    top: 13px;
  }
`;
