import {
  pricingBrackets,
  VIEWING_PACKAGE,
  LISTING_FEE_PERC,
  VIEWING_PACKAGE_PERC
} from './constants';

const getListingFee = (listingPrice) => {
  const bracket = pricingBrackets.find(
    ({ min, max }) => min <= listingPrice && listingPrice <= max
  );
  if (bracket) {
    return bracket.fee;
  }
  return 0;
};

const calculate = ({
  listingPrice,
  competitorCommissionPerc,
  viewingPackage
}) => {
  let percentage = -1;
  let savings = 0;
  let fixedFee = getListingFee(listingPrice);
  const competitorsFee = listingPrice * (competitorCommissionPerc / 100);

  if (fixedFee === 0) {
    // use percentages
    percentage = LISTING_FEE_PERC;

    if (viewingPackage) {
      percentage = percentage + VIEWING_PACKAGE_PERC;
    }
    fixedFee = listingPrice * (percentage / 100);
  } else {
    if (viewingPackage) {
      fixedFee = fixedFee + VIEWING_PACKAGE;
    }
  }
  savings = competitorsFee - fixedFee;
  return { savings, competitorsFee, fixedFee, percentage };
};

export default calculate;
