import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { MD, SM, XS } from '@oxygen/ab/Breakpoints';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  min-height: 500px;
  padding: 70px 10px 110px;
  position: relative;
  background: #1b1d36;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

  ${SM} {
    padding: 50px 10px 80px;
  }

  ${XS} {
    padding: 0px 10px;
  }
`;

export const SectionTitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 90%;
  text-align: center;
  letter-spacing: -3px;
  color: #ffffff;
  margin-bottom: 50px;

  span {
    color: #ff585d;
  }

  ${SM} {
    margin-bottom: 34px;
  }

  ${XS} {
    font-size: 45px;
    line-height: 90%;
    text-align: center;
    letter-spacing: -1.77328px;
    margin-bottom: 27px;
  }
`;

export const SectionSubtext = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 50px;

  ${XS} {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
  }
`;

export const PrimaryButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #ff585d;
      border-radius: 5.2488px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17.0677px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.984675px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
    }
  }
`;

export const CardContent = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

export const Properties = styled.div`
  display: flex;
  position: absolute;
  bottom: -50%;
  transform: translateY(-60%);
`;

export const StyledPropertyCard = styled.div`
  width: 375px;
  height: 210px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;
  position: relative;
  margin-top: ${(props) => (props.mt ? 20 : 0)}px;
  background: url(${(props) => props.photoUrl});
  box-shadow: inset 0px -80px 20px rgba(0, 0, 0, 0.4);

  ${MD} {
    width: 303px;
    height: 210px;
  }
`;

export const Chip = styled.div`
  position: absolute;
  top: 24px;
  left: 0px;
  padding: 6px 12px;
  background: #ff585d;
  color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.38);
  border-radius: 0px 5px 5px 0px;
`;

export const PropertyDetailsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #ffffff;
`;

export const PropertyPrice = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 118%;
  text-align: right;
  color: #ffffff;
`;

export const Attributes = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Attribute = styled.div`
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  padding: 0px 10px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-right: 1px solid #ffffff;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 24px; */
    margin-right: 4px;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;
