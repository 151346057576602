import styled from 'styled-components';
import ImageComponent from 'components/image';
import {
  O2Xxs as O2XxsUpTo319, // These aliases make dev a little easier.
  O2Xs as O2XsUpTo599,
  O2Sm as O2SmUpTo1023,
  O2Md as O2MdUpTo1439,
  toVwPercentage
} from '../o2-breakpoints';

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

  padding: 50px 0 80px;
`;

export const PersonFaceImage = styled(ImageComponent)``;

/****************
 * People
 ****************/
export const FloatingSmallPerson1Wrapper = styled.span`
  position: absolute;
  z-index: -9;

  /* Default: >1440px */
  top: 225px;
  left: ${toVwPercentage(227, 1440)};

  ${O2MdUpTo1439} {
    top: 225px;
    left: ${toVwPercentage(17, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 225px;
    left: ${toVwPercentage(17, 600)};
  }
  ${O2XsUpTo599} {
    top: 200px;
    left: ${toVwPercentage(17, 320)};
    width: 55px;
  }
  ${O2XxsUpTo319} {
    display: none;
  }
`;

export const FloatingMedPerson2Wrapper = styled.span`
  position: absolute;
  z-index: -9;

  /* Default: >1440px */
  top: 240px;
  left: ${toVwPercentage(393, 1440)};

  ${O2MdUpTo1439} {
    top: 240px;
    left: ${toVwPercentage(180, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 356px;
    left: ${toVwPercentage(43, 600)};
  }
  ${O2XsUpTo599} {
    top: 295px;
    left: ${toVwPercentage(37, 320)};
    width: 90px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const FloatingMedPerson3Wrapper = styled.span`
  position: absolute;
  z-index: -9;

  /* Default: >1440px */
  top: 241px;
  left: ${toVwPercentage(962, 1440)};

  ${O2MdUpTo1439} {
    top: 236px;
    left: ${toVwPercentage(753, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 355px;
    left: ${toVwPercentage(402, 600)};
  }
  ${O2XsUpTo599} {
    top: 290px;
    left: ${toVwPercentage(185, 320)};
    width: 90px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const FloatingSmallPerson4Wrapper = styled.span`
  position: absolute;
  z-index: -9;

  /* Default: >1440px */
  top: 223px;
  left: ${toVwPercentage(1156, 1440)};

  ${O2MdUpTo1439} {
    top: 223px;
    left: ${toVwPercentage(941, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 266px;
    left: ${toVwPercentage(508, 600)};
  }
  ${O2XsUpTo599} {
    top: 230px;
    left: ${toVwPercentage(260, 320)};
    width: 55px;
  }
  ${O2XxsUpTo319} {
  }
`;

/****************
 * Shapes
 ****************/
export const BigEllipse1Wrapper = styled.span`
  position: absolute;
  z-index: -10;
  transform: rotate(30deg);

  /* Default: >1440px */
  top: -30px;
  left: -30px;

  ${O2MdUpTo1439} {
    top: 0;
    left: -230px;
  }
  ${O2SmUpTo1023} {
    top: -90px;
    left: -260px;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

export const SmallOrangeEllipse1Wrapper = styled.span`
  position: absolute;
  z-index: -10;

  /* Default: >1440px */
  top: 295px;
  left: ${toVwPercentage(1096, 1440)};
  svg {
    width: 40px;
  }

  ${O2MdUpTo1439} {
    top: 290px;
    left: ${toVwPercentage(888, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 410px;
    left: ${toVwPercentage(533, 600)};
  }
  ${O2XsUpTo599} {
    top: 325px;
    left: ${toVwPercentage(276, 320)};
    svg {
      width: 35px;
      height: 35px;
    }
  }
  ${O2XxsUpTo319} {
  }
`;

export const SmallRedEllipse2Wrapper = styled.span`
  position: absolute;
  z-index: -9;

  /* Default: >1440px */
  top: 221px;
  left: ${toVwPercentage(327, 1440)};
  width: 100px;
  svg {
    width: 60px;
    height: 60px;
  }

  ${O2MdUpTo1439} {
    top: 221px;
    left: ${toVwPercentage(115, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 260px;
    left: ${toVwPercentage(84, 600)};
  }
  ${O2XsUpTo599} {
    top: 227px;
    left: ${toVwPercentage(66, 320)};
    svg {
      width: 35px;
      height: 35px;
    }
  }
  ${O2XxsUpTo319} {
  }
`;

export const BlueLine1Wrapper = styled.span`
  position: absolute;
  z-index: -10;

  /* Default: >1440px */
  top: 263px;
  left: ${toVwPercentage(550, 1440)};
  svg {
    width: 45px;
    height: 45px;
  }

  ${O2MdUpTo1439} {
    top: 260px;
    left: ${toVwPercentage(318, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 350px;
    left: ${toVwPercentage(186, 600)};
  }
  ${O2XsUpTo599} {
    top: 290px;
    left: ${toVwPercentage(140, 320)};
    svg {
      width: 35px;
      height: 35px;
    }
  }
  ${O2XxsUpTo319} {
  }
`;

export const RedLine2Wrapper = styled.span`
  position: absolute;
  z-index: -10;

  /* Default: >1440px */
  top: 280px;
  left: ${toVwPercentage(906, 1440)};
  svg {
    width: 40px;
    height: 40px;
  }

  ${O2MdUpTo1439} {
    top: 250px;
    left: ${toVwPercentage(699, 1024)};
  }
  ${O2SmUpTo1023} {
    top: 368px;
    left: ${toVwPercentage(350, 600)};
  }
  ${O2XsUpTo599} {
    display: none;
  }
  ${O2XxsUpTo319} {
  }
`;

/****************
 * Text
 ****************/
export const ThousandsOf5StarReviewsHeader = styled.div`
  color: #88dbdf;
  font-family: 'Caveat', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 18px;

  /* Default: >1440px */
  font-size: 38px;
  line-height: 86.5%;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    font-size: 25px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const DontTakeOurWordForItHeader = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;

  /* Default: >1440px */
  font-size: 35px;
  line-height: 118%;
  margin-bottom: 37px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    font-size: 25px;
    margin-bottom: 29px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const ReviewsHeader = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #6a6b79;

  /* Default: >1440px */
  font-size: 16.3077px;
  line-height: 148%;
  margin-bottom: 65px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
    margin-bottom: 180px;
  }
  ${O2XsUpTo599} {
    margin-bottom: 120px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const ReviewsOverflowWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ReviewsWrapper = styled.div`
  display: flex;

  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* smooth IOS scrolling */

  /* make sure the child's width is honored, so that edges are cut off as required. */
  & > div {
    flex-shrink: 0;
  }

  /* Default: >1440px */
  flex-direction: row;

  ${O2MdUpTo1439} {
    /* Hide 1st and 4th review on all resolutions except >1440 */
    & > div:nth-child(1) {
      display: none;
    }
    & > div:nth-child(4) {
      display: none;
    }
  }
  ${O2SmUpTo1023} {
    flex-direction: column;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;
