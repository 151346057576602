import styled from 'styled-components';
import {
  O2Xxs as O2XxsUpTo319, // These aliases make dev a little easier.
  O2Xs as O2XsUpTo599,
  O2Sm as O2SmUpTo1023,
  O2Md as O2MdUpTo1439
} from '../../../o2-breakpoints';

export const ReviewCardWrapper = styled.div`
  background-color: #f2f3f6;
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  margin: 12px;

  /* Default: >1440px */
  width: 370px;
  height: 180px;

  img {
    width: 60px;
    height: 60px;
  }

  ${O2MdUpTo1439} {
    width: 315px;
    height: 192px;
  }
  ${O2SmUpTo1023} {
    width: 372px;
    height: 192px;
  }
  ${O2XsUpTo599} {
    width: 288px;
    height: 178px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const ImageSectionWrapper = styled.div`
  display: flex;
  flex-basis: 70px;
  flex-shrink: 0;

  padding-left: 10px;
  padding-top: 10px;
`;

export const TextSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  padding: 16px;

  * {
    margin-top: 16px;
  }

  svg {
    margin-top: 10px;
  }
`;

export const PersonNameCardHeader = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;

  /* Default: >1440px */
  font-size: 16px;
  line-height: 123.5%;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    font-size: 14px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const ReviewBlurb = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;

  /* Default: >1440px */
  font-size: 14px;
  line-height: 123.5%;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    font-size: 12px;
  }
  ${O2XxsUpTo319} {
  }
`;
