import styled from 'styled-components';
import * as colors from '@oxygen/ab/Colors';
import { SM, XS } from '@oxygen/ab/Breakpoints';
import MuiButton from '@material-ui/core/Button';

export const ColumnTitle = styled.p`
  font-family: 'Poppins', sans-serif;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
`;

export const Container = styled.div`
  background: #fff;
  padding: 256px 0px 0px;
  color: #141a2f;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 4px 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 29px;

    color: #6a6b79;

    @media (hover: hover) {
      &:hover {
        color: #141a2f;
      }
    }
  }

  ${SM} {
    padding: 180px 0px 0px;
    ${ColumnTitle} {
      margin-top: 32px;
    }
  }
`;

export const NewsletterContainer = styled.div`
  padding: 26px;
  border: 1px solid #b1b2c0;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NewsletterTitle = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15.7026px;
  line-height: 27px;
  color: #6a6b79;
`;

export const SmallText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 9.85997px;
  line-height: 20px;
  color: #6a6b79;
`;

export const InputContainer = styled.div`
  background: ${colors.white};
  border: 2px solid #ff585d;
  border-right: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 15px 0px;
`;

export const Input = styled.input`
  flex: 1;
  padding: 0px 10px;
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.storm3};
  font-family: 'Poppins', sans-serif;

  &::placeholder {
    font-size: 1.5rem;
    color: #ea5355;
  }
`;

export const StyledLinkButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #ff585d;
      border-radius: 5.2488px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
      height: 50px;
      border-radius: 0px 4px 4px 0px;
      width: 60px;
    }
  }
`;

export const OutroContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  color: #141a2f;

  ${SM} {
    flex-direction: column-reverse;
    padding-top: 32px;
  }
`;

export const BrandRegion = styled.div`
  padding-right: 16px;
  display: flex;
  align-items: center;
`;

export const NavRegion = styled.div`
  color: #141a2f;
  display: flex;
  align-items: center;

  ${XS} {
    flex-direction: column-reverse;
  }
`;

export const NavItem = styled.div`
  padding: 8px;
  color: #141a2f;

  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    color: #141a2f;

    @media (hover: hover) {
      &:hover {
        color: ${colors.aqua};
      }
    }
  }
`;

export const NavSeparator = styled.div`
  width: 1px;
  height: 16px;
  background: #141a2f;
  margin: 0px 16px;

  ${SM} {
    display: none;
  }
`;

export const SocialRegion = styled.div`
  display: flex;
  align-items: center;
`;
