import React from 'react';
import PropTypes from 'prop-types';

import { ResultsHeader as StyledResultsHeader, LoadingBar } from './styles';

const ResultsHeader = ({ noResults, fetching }) => {
  let headerText = 'Searching';

  if (noResults) {
    headerText = 'No results';
  } else if (!noResults) {
    headerText = 'Results';
  } else {
    headerText = 'Searching';
  }

  return (
    <StyledResultsHeader>
      {headerText} {fetching && <LoadingBar />}
    </StyledResultsHeader>
  );
};

ResultsHeader.propTypes = {
  noResults: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired
};

export default ResultsHeader;
