import React, { useState } from 'react';
import {
  InnerWrapper,
  OuterWrapper,
  LeftSectionWrapper,
  SmallLogosWrapper,
  LaptopImage,
  MorseCodeImage,
  SmallLogoImage,
  HoverOrClickOnTheLogosText,
  Our4MajorTechInnovationsText,
  WeveBuiltTheFirstModernText,
  BigEllipseSvgWrapper,
  TinyRedSquareSvgWrapper,
  WhoahArrowSvgWrapper,
  StarSvgWrapper,
  RightSectionWrapper,
  WhoahText,
  LaptopImageWrapper
} from './styles';

import DefaultLaptopPng from './images/default-laptop.png';
import BloomLaptopPng from './images/bloom-laptop.png';
import BloomLogoPng from './images/bloom-logo.png';
import BondsparkLaptopPng from './images/bondspark-laptop.png';
import BondsparkLogoPng from './images/bondspark-logo.png';
import LeadhomeLaptopPng from './images/leadhome-laptop.png';
import LeadhomeLogoPng from './images/leadhome-logo.png';
import OsmoLaptopPng from './images/osmo-laptop.png';
import OsmoLogoPng from './images/osmo-logo.png';

import MorseCodePng from './images/morse-code.png';

import BigEllipseSvg from './images/big-ellipse.svg';
import StarSvg from './images/star.svg';
import TinyRedSquareSvg from './images/tiny-red-square.svg';
import WhoahArrowSvg from './images/whoah-arrow.svg';

const TechInnovations = () => {
  const [laptopImageToUse, setLaptopImageToUse] = useState(DefaultLaptopPng);

  function switchToLaptopImageNr(newImage) {
    setLaptopImageToUse(newImage);
  }

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LeftSectionWrapper>
          <Our4MajorTechInnovationsText>
            Our 4 major tech innovations
          </Our4MajorTechInnovationsText>
          <WeveBuiltTheFirstModernText>
            We&apos;ve built the first modern real estate platform, pairing top
            talent with technology to make the search and sell experience
            intelligent and seamless. We use tech to connect you to everything
            to do with your property and more, we also go the extra mile by
            helping you get a bond on your property.
          </WeveBuiltTheFirstModernText>
          <HoverOrClickOnTheLogosText>
            Hover or click on the logos to view our tech
          </HoverOrClickOnTheLogosText>
          <SmallLogosWrapper>
            <SmallLogoImage
              onMouseEnter={() => switchToLaptopImageNr(LeadhomeLaptopPng)}
              onClick={() => switchToLaptopImageNr(LeadhomeLaptopPng)}
              src={LeadhomeLogoPng}
              alt='Leadhome logo'
            />
            <SmallLogoImage
              onMouseEnter={() => switchToLaptopImageNr(BloomLaptopPng)}
              onClick={() => switchToLaptopImageNr(BloomLaptopPng)}
              src={BloomLogoPng}
              alt='Bloom logo'
            />
            <SmallLogoImage
              onMouseEnter={() => switchToLaptopImageNr(BondsparkLaptopPng)}
              onClick={() => switchToLaptopImageNr(BondsparkLaptopPng)}
              src={BondsparkLogoPng}
              alt='Bondspark logo'
            />
            <SmallLogoImage
              onMouseEnter={() => switchToLaptopImageNr(OsmoLaptopPng)}
              onClick={() => switchToLaptopImageNr(OsmoLaptopPng)}
              src={OsmoLogoPng}
              alt='Osmo logo'
            />
          </SmallLogosWrapper>
        </LeftSectionWrapper>

        <RightSectionWrapper>
          <WhoahText>woah!</WhoahText>
          <WhoahArrowSvgWrapper>
            <WhoahArrowSvg />
          </WhoahArrowSvgWrapper>
          <LaptopImageWrapper>
            <LaptopImage
              showMe={laptopImageToUse === DefaultLaptopPng}
              src={DefaultLaptopPng}
              alt='Leadhome laptop'
            />
            <LaptopImage
              showMe={laptopImageToUse === LeadhomeLaptopPng}
              src={LeadhomeLaptopPng}
              alt='Leadhome laptop'
            />
            <LaptopImage
              showMe={laptopImageToUse === BondsparkLaptopPng}
              src={BondsparkLaptopPng}
              alt='Bondspark laptop'
            />
            <LaptopImage
              showMe={laptopImageToUse === BloomLaptopPng}
              src={BloomLaptopPng}
              alt='Bloom laptop'
            />
            <LaptopImage
              showMe={laptopImageToUse === OsmoLaptopPng}
              src={OsmoLaptopPng}
              alt='Osmo laptop'
            />
          </LaptopImageWrapper>
          <MorseCodeImage src={MorseCodePng} alt='morse code image' />
          <BigEllipseSvgWrapper>
            <BigEllipseSvg />
          </BigEllipseSvgWrapper>
          <TinyRedSquareSvgWrapper>
            <TinyRedSquareSvg />
          </TinyRedSquareSvgWrapper>
          <StarSvgWrapper>
            <StarSvg />
          </StarSvgWrapper>
        </RightSectionWrapper>
      </InnerWrapper>
    </OuterWrapper>
  );
};

TechInnovations.propTypes = {};

export default React.memo(TechInnovations);
