import React from 'react';
import PropTypes from 'prop-types';
import Newsletter from './newsletter';
import Grid from '@material-ui/core/Grid';
import { LeadhomeBrand } from '@oxygen/ab/Logo';
import SocialBar from '@oxygen/ab/SocialBar';
import * as navData from '@oxygen/ab/NavData';
import MaterialContainer from '@material-ui/core/Container';
import {
  ColumnTitle,
  Container,
  BrandRegion,
  NavRegion,
  NavItem,
  NavSeparator,
  OutroContainer,
  SocialRegion
} from './style';

function Footer({ maxWidth }) {
  return (
    <Container>
      <MaterialContainer maxWidth={maxWidth}>
        <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
            <ColumnTitle>How we do it</ColumnTitle>
            <a href='/guides/home-sellers'>How selling works</a>
            <a href='/guides/home-buyers'>How buying works</a>
            <a href='/frequently-asked-questions'>Frequently asked questions</a>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <ColumnTitle>Why Leadhome</ColumnTitle>
            <a href={navData.reviews.href}>Client reviews</a>
            <a href={navData.ourAgents.href}>Real estate agents</a>
            <a href='/where-we-operate'>Where we operate</a>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <ColumnTitle>Who we are</ColumnTitle>
            <a href={navData.careersAll.href}>{navData.careers.title}</a>
            <a href={navData.meetTheTeam.href}>{navData.meetTheTeam.title}</a>
            <a href='/about'>About us</a>
            <a href='/contact-us'>Contact us</a>
            <a href='/blog'>Blog</a>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <ColumnTitle>Tools</ColumnTitle>
            <a href={navData.affordabilityCalculator.href}>
              Affordability calculator
            </a>
            <a href={navData.repaymentCalculator.href}>Repayment calculator</a>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Newsletter />
          </Grid>
        </Grid>
        <OutroContainer>
          <BrandRegion>
            <a href='/'>
              <LeadhomeBrand alt='Leadhome' title='Leadhome' />
            </a>
            <NavItem>&copy; Leadhome Pty Ltd</NavItem>
          </BrandRegion>
          <NavRegion>
            <SocialRegion>
              <SocialBar />
            </SocialRegion>
            <NavSeparator />
            <NavItem>
              <a href='/terms-of-use'>Terms of use</a>
            </NavItem>
          </NavRegion>
        </OutroContainer>
      </MaterialContainer>
    </Container>
  );
}

Footer.propTypes = {
  maxWidth: PropTypes.string
};

export default React.memo(Footer);
