export const shortCurrency = (value = 0) => {
  if (typeof value === 'string') {
    const noCommas = value.replace(/,/g, '');
    value = parseFloat(noCommas);
  }
  if (Math.round(value) >= 1000000)
    return `${Math.round(value / 10000) / 100}m`;
  return `${Math.round(value / 1000)}k`;
};

export const shortZAR = (value) => `R${shortCurrency(value)}`;

export const toCurrency = (value) =>
  value ? Number(value).toLocaleString('en') : null;

export const toPercentage = (value) =>
  value ? Number(value).toLocaleString('en') + '%' : null;

export const currencyToNumber = (value) =>
  typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

export const formatEnum = (value) => {
  try {
    if (!value) return value;

    const result = value?.toLowerCase().replace(/_/gi, ' ') || '';
    return `${result.substr(0, 1).toUpperCase()}${result.substr(1)}`;
  } catch (err) {
    throw new Error(err);
  }
};

export const toKebabCase = (value) =>
  value && value.replace(/\s+/g, '-').toLowerCase();

export const kebabToTitleCase = (value) =>
  value &&
  value
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export const normalizePhoneNumber = (phone) => {
  // normalize string and remove all unnecessary characters
  const temp = phone.replace(/[^\d]/g, '');
  // check if number length equals to 10
  if (temp.length === 10) {
    // reformat and return phone number
    return temp.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
  }
  return phone;
};

export const toBoolean = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return !!value;
};
