/* eslint-disable indent */
import styled from 'styled-components';
import ImageComponent from '../../image';
import {
  O2Xxs as O2XxsUpTo319, // These aliases make dev a little easier.
  O2Xs as O2XsUpTo599,
  O2Sm as O2SmUpTo1023,
  O2Md as O2MdUpTo1439
} from '../o2-breakpoints';

export const OuterWrapper = styled.section`
  margin: 0 0 180px 0;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
    margin: 0 0 50px 0;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1100px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
    flex-direction: column;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

//* ******************
//* Left section.
//* ******************
export const LeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;

  ${O2MdUpTo1439} {
    margin-left: 30px;
  }
  ${O2SmUpTo1023} {
    margin: 0 auto;
    padding: 0 20px 0 20px;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

export const Our4MajorTechInnovationsText = styled.h4`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 118%;
  color: #141a2f;

  font-size: 35px;
  margin-top: 180px;

  ${O2MdUpTo1439} {
    margin-top: 160px;
  }
  ${O2SmUpTo1023} {
    margin-top: 50px;
  }
  ${O2XsUpTo599} {
    margin-top: 35px;
    font-size: 25px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const WeveBuiltTheFirstModernText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 152.5%;

  font-size: 17px;
  margin-top: 40px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    margin-top: 25px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const HoverOrClickOnTheLogosText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 152.5%;
  color: #6a6b79;

  font-size: 14px;
  margin-top: 26px;

  ${O2MdUpTo1439} {
    font-size: 17px;
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

export const SmallLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 0 0;

  margin-top: 20px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    margin-top: 0;
  }
  ${O2XxsUpTo319} {
  }
`;
export const SmallLogoImage = styled(ImageComponent)`
  margin: 0 10px 0 10px;
  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
  }
  ${O2XsUpTo599} {
    margin-top: 35px;
  }
  ${O2XxsUpTo319} {
  }
`;

//* ******************
//* Right section.
//* ******************
export const RightSectionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  /* Default width >= 1440 */
  min-width: 568px;

  ${O2MdUpTo1439} {
    width: 490px;
    margin-right: -97px;
  }
  ${O2SmUpTo1023} {
    margin: 0 auto;
    height: 550px;
  }
  ${O2XsUpTo599} {
    width: 320px;
    min-width: 320px;
    height: 330px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const WhoahText = styled.div`
  position: absolute;
  font-family: 'Caveat', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 86.5%;
  color: #ff585d;
  font-size: 38px;
  z-index: -5;

  top: 100px;
  left: 70px;

  ${O2MdUpTo1439} {
    top: 100px;
  }
  ${O2SmUpTo1023} {
    top: 80px;
  }
  ${O2XsUpTo599} {
    top: 35px;
    left: 50px;
    font-size: 25px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const WhoahArrowSvgWrapper = styled.div`
  position: absolute;
  z-index: -5;

  top: 120px;
  left: 140px;

  ${O2MdUpTo1439} {
    top: 120px;
  }
  ${O2SmUpTo1023} {
    top: 100px;
  }
  ${O2XsUpTo599} {
    top: 40px;
    left: 90px;
    svg {
      width: 45px;
    }
  }
  ${O2XxsUpTo319} {
  }
`;

export const LaptopImageWrapper = styled.div`
  position: absolute;
  z-index: -7;
  width: auto;
  margin-bottom: -40px;
  bottom: 0;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
    margin-bottom: 0;
    bottom: 15px;
  }
  ${O2XsUpTo599} {
    width: 350px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const LaptopImage = styled(ImageComponent)`
  ${(props) =>
    props.showMe
      ? 'visibility: visible;'
      : 'position: absolute; display: none;'}
`;

export const MorseCodeImage = styled(ImageComponent)`
  position: absolute;
  z-index: -9;
  left: 345px;
  top: 375px;

  ${O2MdUpTo1439} {
    top: 420px;
  }
  ${O2SmUpTo1023} {
    top: 350px;
    left: 380px;
  }
  ${O2XsUpTo599} {
    top: 150px;
    left: 180px;
    width: 180px;
  }
  ${O2XxsUpTo319} {
  }
`;

export const BigEllipseSvgWrapper = styled.div`
  position: absolute;
  z-index: -10;
  transform: scale(-1, 1) rotate(40deg);
  top: -70px;
  left: 200px;
  svg {
  }

  ${O2MdUpTo1439} {
    transform: scale(-1, 1) rotate(80deg);
    top: -20px;
  }
  ${O2SmUpTo1023} {
    top: -40px;
  }
  ${O2XsUpTo599} {
    display: none; /* Causes horiz scrolling issues */
    top: -160px;
    left: 150px;
    svg {
      width: 300px;
    }
  }
  ${O2XxsUpTo319} {
  }
`;

export const TinyRedSquareSvgWrapper = styled.div`
  position: absolute;
  z-index: -5;

  top: 185px;
  left: 495px;

  ${O2MdUpTo1439} {
  }
  ${O2SmUpTo1023} {
    display: none;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;

export const StarSvgWrapper = styled.div`
  position: absolute;
  z-index: -8;
  top: 140px;
  left: 20px;

  ${O2MdUpTo1439} {
    top: 190px;
  }
  ${O2SmUpTo1023} {
    top: 120px;
    left: 40px;
  }
  ${O2XsUpTo599} {
  }
  ${O2XxsUpTo319} {
  }
`;
