import React from 'react';
import Grid from '@material-ui/core/Grid';
import SellingExperienceCard from './selling-experience-card';
import MorseCodePngSrc from './images/morse-code.png';
import WhiteLineSvg from './images/white-line.svg';
import BlueBlockSvg from './images/blue-block.svg';
import SmallYellowEllipseSvg from './images/yellow-ellipse.svg';
import {
  Wrapper,
  SectionTitle,
  SectionSubtext,
  DarkButton,
  Steps,
  MorseCodeImage,
  WhiteLineSvgWrapper,
  BlueBlockSvgWrapper,
  SmallYellowEllipseSvgWrapper
} from './styles';

const steps = [
  {
    stepNo: 1,
    stepNoWord: (
      <>
        <span>S</span>tep one
      </>
    ),
    title: 'Meet your local expert Estate Agent',
    description:
      'Deal with agents that know your area, with tertiary degrees and EAAB qualifications.'
  },
  {
    stepNo: 2,
    stepNoWord: (
      <>
        <span>S</span>tep two
      </>
    ),
    title: 'Get your home listed',
    description:
      'We capture your home and all its amazing qualities with  beautiful wide angles photos to showcase your home at its best.'
  },
  {
    stepNo: 3,
    stepNoWord: (
      <>
        <span>S</span>tep three
      </>
    ),
    title: 'Monitor your home remotely',
    description:
      'See feedback from potential buyers along with data on your home’s performance anytime, anywhere with smart technology. '
  },
  {
    stepNo: 4,
    stepNoWord: (
      <>
        <span>S</span>tep four
      </>
    ),
    title: "Oops! It's already sold",
    description:
      'We’ll negotiate to get you the best offer on your home and we will simplify all the complex paperwork.'
  }
];

const SellingExperienceSection = () => (
  <Wrapper>
    <SectionTitle>The Leadhome selling experience</SectionTitle>
    <SectionSubtext>Hover or click to view more information</SectionSubtext>
    <Steps>
      <MorseCodeImage src={MorseCodePngSrc} alt='morse code image' />
      <WhiteLineSvgWrapper>
        <WhiteLineSvg />
      </WhiteLineSvgWrapper>
      <BlueBlockSvgWrapper>
        <BlueBlockSvg />
      </BlueBlockSvgWrapper>
      <SmallYellowEllipseSvgWrapper>
        <SmallYellowEllipseSvg />
      </SmallYellowEllipseSvgWrapper>
      <Grid container spacing={2} justifyContent='center'>
        {steps.map((step) => (
          <Grid
            key={`step-${step.stepNo}`}
            container
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
            justifyContent='center'
          >
            <SellingExperienceCard step={step} />
          </Grid>
        ))}
      </Grid>
    </Steps>
    <DarkButton component='a' href='/valuation/'>
      Book a free evaluation
    </DarkButton>
  </Wrapper>
);

export default React.memo(SellingExperienceSection);
