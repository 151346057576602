import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from 'utils/format';
import Link from '@material-ui/core/Link';
import { BedsIcon, BathsIcon, ParkingIcon } from '../icons';
import {
  Chip,
  Attribute,
  Attributes,
  PropertyPrice,
  StyledPropertyCard,
  PropertyDetailsContainer
} from './styles';

const PropertyCard = ({ mt, property }) => (
  <Link component='a' href={property.searchUrl}>
    <StyledPropertyCard mt={mt} photoUrl={property.photoUrl}>
      <Chip>{property.suburb}</Chip>
      <PropertyDetailsContainer>
        <Attributes>
          <Attribute>
            <span>{property.baths}</span>
            <BathsIcon />
          </Attribute>
          <Attribute>
            <span>{property.beds}</span>
            <BedsIcon />
          </Attribute>
          <Attribute>
            <span>{property.parking}</span>
            <ParkingIcon />
          </Attribute>
        </Attributes>
        <PropertyPrice>R{toCurrency(property.price)}</PropertyPrice>
      </PropertyDetailsContainer>
    </StyledPropertyCard>
  </Link>
);

PropertyCard.propTypes = {
  mt: PropTypes.bool,
  property: PropTypes.any
};

export default React.memo(PropertyCard);
