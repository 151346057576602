import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../../hooks/use-debounce';
import useSWR from 'swr';

// components
import ResultsHeader from './results-header';
import {
  Container,
  SearchInputContainer,
  Input,
  ResultsContainer,
  ResultItem,
  SearchIcon,
  MapIcon,
  SuburbName,
  TownName,
  StyledLinkButton
} from './styles';
import { fetcher } from '../../../utils/fetcher';

const fetchWithSearch = (url, search) => {
  return fetcher(url, {
    query: search,
    size: 5
  });
};

const SearchInput = ({
  id,
  buttonText,
  placeholder,
  onButtonClick,
  toPropertySearch,
  disableUnsupported,
  hideActionButton = false,
  setExternalValue,
  externalValueFieldName,
  error
}) => {
  const [hasFocus, setFocus] = useState(false);
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 300);

  const { data: results, isValidating } = useSWR(
    debouncedSearch
      ? ['/api/valuation-wizard/search/suburbs', debouncedSearch]
      : null,
    fetchWithSearch
  );

  const noResults = !isValidating && results?.length === 0;

  const redirectToValWiz = () => {
    const url = selectedSuburb
      ? `/valuation/?suburb=${selectedSuburb.suburb}`
      : `/valuation/`;
    window.location = url;
  };

  const redirectToPropertySearch = () => {
    const url = selectedSuburb
      ? `/search/property-for-sale?s=${selectedSuburb.id}`
      : `/search`;
    window.location = url;
  };

  return (
    <Container>
      <SearchInputContainer
        hideActionButton
        resultsVisible={hasFocus && search}
        error={error}
      >
        <Input
          name='suburbSearch'
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          value={search}
          autoComplete='off'
          placeholder={placeholder || 'Enter suburb'}
        />
      </SearchInputContainer>
      {!hideActionButton && (
        <StyledLinkButton
          id={id}
          onClick={() => {
            if (!selectedSuburb) {
              return;
            }
            if (onButtonClick) {
              onButtonClick(selectedSuburb);
            } else if (toPropertySearch) {
              redirectToPropertySearch();
            } else {
              redirectToValWiz();
            }
          }}
        >
          {buttonText}
        </StyledLinkButton>
      )}

      <ResultsContainer visible={hasFocus && search}>
        <ResultsHeader noResults={noResults} fetching={isValidating} />
        {hasFocus &&
          results &&
          results.map((result, i) => {
            const disable = disableUnsupported && !result.isSupported;
            const suburbText =
              (disable && `${result.suburb} (Unsupported)`) || result.suburb;
            return (
              <ResultItem
                key={i}
                onMouseDown={() => {
                  setSelectedSuburb(result);
                  setSearch(result.suburb);
                  if (setExternalValue) {
                    setExternalValue(externalValueFieldName, result.id);
                  }
                }}
                disable={disable}
              >
                {result.icon === 'search' ? <SearchIcon /> : <MapIcon />}
                <div data-e2e-id='resultItem'>
                  <SuburbName>{suburbText}</SuburbName>
                  <TownName>{result.city}</TownName>
                </div>
              </ResultItem>
            );
          })}
      </ResultsContainer>
    </Container>
  );
};

SearchInput.defaultProps = {
  disableUnsupported: false,
  hideLabel: false
};

SearchInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  toPropertySearch: PropTypes.bool,
  disableUnsupported: PropTypes.bool,
  hideActionButton: PropTypes.bool,
  setExternalValue: PropTypes.func,
  externalValueFieldName: PropTypes.string,
  error: PropTypes.bool
};

export default SearchInput;
