import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { MD, SM, XS } from '@oxygen/ab/Breakpoints';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  min-height: 500px;
  padding: 80px 10px 122px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

  ${SM} {
    padding: 50px 10px 30px;
  }
`;

export const SectionTitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 118%;
  text-align: center;
  color: #141a2f;
  max-width: 700px;
  margin-bottom: 38px;

  ${SM} {
    margin-bottom: 34px;
  }

  ${XS} {
    font-size: 25px;
    line-height: 118%;
    margin-bottom: 27px;
  }
`;

export const SectionSubtext = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 136%;
  text-align: center;
  color: #6a6b79;
  max-width: 700px;
  margin-bottom: 50px;

  ${XS} {
    font-size: 15px;
  }
`;

export const PrimaryButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #ff585d;
      border-radius: 5.2488px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17.0677px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.984675px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
    }
  }
`;

export const CallUsButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #1b1d36;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
    }
  }
`;

export const WhatsAppButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #25d366;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
      margin-left: 13px;
    }
  }
`;

export const StatsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 47px;

  ${MD} {
    flex-direction: column;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  ${MD} {
    margin-bottom: 47px;
  }

  ${XS} {
    flex-direction: column;
  }
`;

export const StatsBox = styled.div`
  max-width: 190px;
  padding-right: 45px;
  flex: 1;

  ${SM} {
    width: 100%;
    max-width: 270px;
    margin-bottom: 26px;
    padding: 0px 20px 0px 0px;
  }
`;

export const StatsBoxWithBorder = styled.div`
  border-left: 3.0016px solid #f2f3f6;
  padding: 0px 52px;
  max-width: 270px;
  height: 100%;
  flex: 1;

  ${SM} {
    width: 100%;
    padding: 0px 27px;
    margin-bottom: 26px;
  }

  ${XS} {
    padding: 0px;
    border: none;
  }
`;

export const StatsTitle = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  letter-spacing: 1px;
  color: #1b1d36;
`;

export const StatsDesc = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.5%;
  color: #6a6b79;
`;
