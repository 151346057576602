import React from 'react';
import { ArrowRightIcon } from '@oxygen/ab/Icons';
import {
  NewsletterContainer,
  NewsletterTitle,
  StyledLinkButton,
  SmallText,
  InputContainer,
  Input
} from './style';

function Newsletter() {
  const [email, setEmail] = React.useState('');
  const handleReirectToSubscribe = () => {
    window.location = `/subscribe?email=${email || ''}`;
  };
  return (
    <NewsletterContainer>
      <NewsletterTitle>Sign up for our newsletter</NewsletterTitle>
      <InputContainer>
        <Input
          value={email}
          placeholder='Email address'
          onChange={(e) => setEmail(e.target.value)}
        />
        <StyledLinkButton onClick={handleReirectToSubscribe}>
          <ArrowRightIcon />
        </StyledLinkButton>
      </InputContainer>
      <SmallText>
        Stay up to date with our latest tool tips to help you sell your home.
      </SmallText>
    </NewsletterContainer>
  );
}

export default React.memo(Newsletter);
