import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  width: 100%;
  justify-content: center;

  /* Default: >1440px */
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
