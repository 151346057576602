import styled, { keyframes } from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { MD, SM, XS } from '@oxygen/ab/Breakpoints';

const slideInKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  min-height: 500px;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
`;

export const SmWrapper = styled.section`
  width: 100%;
  padding: 50px 10px 0px;
  font-family: 'Poppins', sans-serif;
`;

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 90%;
  letter-spacing: -0.03em;
  color: #1b1d36;
  margin-bottom: 30px;

  em {
    font-style: normal;
    color: #ff585d;
    white-space: nowrap;
  }

  ${MD} {
    font-size: 67px;
  }

  ${XS} {
    font-size: 40px;
  }
`;

export const Subtitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 134%;
  color: #1b1d36;

  ${XS} {
    font-size: 15px;
  }
`;

export const PricingBox = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-bottom: 4px solid #ff585d;

  .fade-out {
    opacity: 0;
    width: 0px;
    height: 0px;
    transition: width 0s, height 0s;
  }

  .slide-in {
    opacity: 1;
    width: fit-content;
    transition: width 0s, opacity 1s linear;
    animation: ${slideInKeyframes} 1s ease-in-out;
  }
`;

export const BuyAndSell = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  opacity: 1;
`;

export const SellOnly = styled.div`
  display: flex;
  align-items: center;
  width: 0px;
  opacity: 0;
`;

export const Price = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 120%;
  letter-spacing: 1px;
  color: #1b1d36;
  margin-right: 5px;

  ${XS} {
    font-size: 43px;
  }
`;

export const PriceTerms = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 118%;
  color: #6a6b79;

  ${XS} {
    font-size: 15px;
  }
`;

export const ImageSection = styled.div`
  margin-left: 60px;
  max-width: 500px;
  width: 100%;

  ${SM} {
    margin: 0px auto;
  }
`;

export const CallToActionWrapper = styled.div`
  margin: 60px 0px 20px;
`;

export const CallUsButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #1b1d36;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
    }
  }
`;

export const WhatsAppButton = styled(MuiButton)`
  && {
    &,
    &:hover {
      height: auto;
      background: #25d366;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5628px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 12px 25px;
      margin-left: 13px;
    }
  }
`;

export const BigGreyEllipseWrapper = styled.span`
  position: absolute;
  z-index: -10;
  left: 1100px;

  svg {
    transform: rotate(-30.11deg);
  }
`;
