import React from 'react';
import { SvgIcon } from '@material-ui/core';

function BathsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M1.899 3.38586C1.899 2.25936 2.9041 1.34616 4.14396 1.34616H5.49093V2.16204H6.38892V0.530273H4.14396C2.40816 0.530273 1.00101 1.80876 1.00101 3.38586V6.24145H0.103027V7.05734H1.00101V8.28116C1.00101 9.71976 2.17185 10.9099 3.69497 11.1078V12.7685H4.59295V11.1368H9.08287V12.7685H9.98086V11.1078C11.504 10.9099 12.6748 9.71976 12.6748 8.28116V7.05734H13.5728V6.24145H1.899V3.38586Z'
        fill='currentColor'
      />
      <path
        d='M7.2869 3.79381H4.59295V2.97792H7.2869V3.79381Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

BathsIcon.defaultProps = {
  viewBox: '0 0 14 13'
};

export default React.memo(BathsIcon);
