import React from 'react';
import Box from '@material-ui/core/Box';
import { WhatsAppIcon } from '@oxygen/ab/Icons';
import AnimatedPricing from './animated-pricing';
import { whatsappUrl } from '@oxygen/ab/WhatsApp';
import image from './images/family.png?resize'; // eslint-disable-line import/no-unresolved
import LazyImage from 'components/lazy-image';
import SuburbSearchInput from '../suburb-search-input';
import {
  Title,
  Wrapper,
  Subtitle,
  ImageSection,
  CallUsButton,
  WhatsAppButton,
  CallToActionWrapper
} from './styles';

const MdUpHeroSection = () => (
  <Wrapper>
    <div>
      <Title>
        Low fixed fees.
        <br />
        <em>Less drama.</em>
      </Title>
      <Subtitle>Sell your home from as little as</Subtitle>

      <AnimatedPricing />
      <CallToActionWrapper>
        <SuburbSearchInput
          placeholder='Where is your suburb'
          buttonText='Book a free evaluation'
        />
      </CallToActionWrapper>
      <Box display='flex' alignItems='center'>
        <CallUsButton component='a' href='tel:+27105903088'>
          Call us
        </CallUsButton>
        <WhatsAppButton
          component='a'
          target='_blank'
          href={whatsappUrl}
          startIcon={<WhatsAppIcon />}
        >
          Whatsapp
        </WhatsAppButton>
      </Box>
    </div>
    <ImageSection>
      <LazyImage
        src={image.src}
        srcset={image.srcSet}
        placeholder={image.placeholder}
        title='Hero image'
        alt='Hero image'
      />
    </ImageSection>
  </Wrapper>
);

export default React.memo(MdUpHeroSection);
