import React from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { WhatsAppIcon } from '@oxygen/ab/Icons';
import AnimatedPricing from './animated-pricing';
import { whatsappUrl } from '@oxygen/ab/WhatsApp';
import image from './images/family.png?resize'; // eslint-disable-line import/no-unresolved
import LazyImage from 'components/lazy-image';
import SuburbSearchInput from '../suburb-search-input';
import {
  Title,
  Subtitle,
  SmWrapper,
  ImageSection,
  CallUsButton,
  WhatsAppButton,
  CallToActionWrapper
} from './styles';

const SmDownHeroSection = () => (
  <SmWrapper>
    <Title>
      <span>Low fixed fees.</span>
      <em> Less drama. </em>
    </Title>
    <ImageSection>
      <LazyImage
        src={image.src}
        srcset={image.srcSet}
        placeholder={image.placeholder}
        title='Hero image'
        alt='Hero image'
      />
    </ImageSection>
    <Subtitle>Sell your home from as little as</Subtitle>
    <AnimatedPricing />
    <CallToActionWrapper>
      <Hidden xsDown>
        <SuburbSearchInput
          placeholder='Where is your suburb'
          buttonText='Book a free evaluation'
        />
      </Hidden>
      <Hidden smUp>
        <SuburbSearchInput
          placeholder='Enter your suburb'
          buttonText='Book a evaluation'
        />
      </Hidden>
    </CallToActionWrapper>
    <Box display='flex' alignItems='center'>
      <CallUsButton component='a' href='tel:+27105903088'>
        Call us
      </CallUsButton>
      <WhatsAppButton
        component='a'
        target='_blank'
        href={whatsappUrl}
        startIcon={<WhatsAppIcon />}
      >
        Whatsapp
      </WhatsAppButton>
    </Box>
  </SmWrapper>
);

export default React.memo(SmDownHeroSection);
